import React, { Component } from "react"
import "../../myStyle.css"
import "./AboutEvolution.css"
import TextStyle from "../style-compoment/TextStyle"
import { StaticImage } from "gatsby-plugin-image"

class AboutEvolution extends Component {

    render () {

        return (    
            <div className="aboutEvolution-container">
                <TextStyle
                    id="a-21"
                    class="title center big animate"
                    french={["Évolution du ", <span className="orange">Kodness</span>]} 
                    english="Evolution of the TRAINFAST" 
                    spanish="Evolución del TRAINFAST"
                    cssStyle={{marginTop: "50px", marginBottom: "50px", textAlign: "center"}}
                    ></TextStyle>
                <div className="aboutEvolution-images-container">
                    <div className="aboutEvolution-trainfast">
                        <StaticImage
                            className="aboutEvolution-images"
                            src="../../images/about-images/Trainfast-2.jpg"
                            alt="Second demonstrator"
                        />
                        <TextStyle
                            class="small center italic"
                            french="Premier demonstrateur" 
                            english="First demonstrator" 
                            spanish="primer demostrador"
                        ></TextStyle>
                    </div>
                    <div className="aboutEvolution-trainfast">
                        <StaticImage
                            className="aboutEvolution-images"
                            src="../../images/about-images/trainfast-1.jpg"
                            alt="First demonstrator"
                        />
                        <TextStyle
                            class="small center italic"
                            french="Deuxième démonstrateur en construction" 
                            english="Second demonstrator under construction" 
                            spanish="Segundo demostrador en construcción"
                        ></TextStyle>
                    </div>
                    <div className="aboutEvolution-trainfast">
                        <StaticImage
                            className="aboutEvolution-images"
                            src="../../images/about-images/Trainfast-3.jpg"
                            alt="third demonstrator"
                        />
                        <TextStyle
                            class="small center italic"
                            french="Troisième démonstrateur" 
                            english="Third demonstrator" 
                            spanish="Tercer demostrador"
                        ></TextStyle>
                    </div>
                </div>

            </div>
        )

    }
}

export default AboutEvolution
