import React, { Component } from "react"
import "../../myStyle.css"

class TransitionBar extends Component {


    render () {


        return (

            <div style={transitionBar}>
            </div>
        )

    }
}

export default TransitionBar


const transitionBar = {
    width: "100%",
    height: "6px",
    backgroundColor: "#ebebeb",
    margin: "auto",
}