import React, { Component } from "react"
import "../../myStyle.css"
import "./AboutReason.css"
import TextStyle from "../style-compoment/TextStyle"
import Apostrophe from "../../images/about-images/apostrophe.png"
import { StaticImage } from "gatsby-plugin-image"

class AboutReason extends Component {


    render () {

       

        return (    
            <div className="aboutReason-container">
                <TextStyle
                    id="a-11"
                    class="title big center animate"
                    french={["Pourquoi", <span className="orange"> Kodness ?</span>]}
                    english="Why TRAINFAST?"
                    spanish="¿Por qué TRAINFAST?"
                    cssStyle={{marginTop: "120px", marginBottom: "50px"}}
                ></TextStyle>
                <div className="aboutReason-second">
                    <StaticImage
                        className="aboutReason-image"
                        src="../../images/about-images/founder.jpg"
                        alt="founder-Thomas Perrier"
                    />
                    <div id="a-12" className="aboutReason-text-container animate">
                        <img className="aboutReason-apostrophe" src={Apostrophe} alt=""></img>
                        <TextStyle
                            class="small"
                            french={["Lorsque j'ai commence à imaginer le KODNESS, c'était avec l’intention de simplifier la pratique du fitness, de manière à le rendre accessible à tous !",
                            <br></br>, <br></br>,
                            "En effet, je n’avais pas suffisamment d’espace pour installer une station de musculation classique, donc volumineuse, et je n’étais pas satisfait des solutions compactes existantes, souvent compliqué à utiliser et peu pratique pour une utilisation régulière.",
                            <br></br>, <br></br>,
                            "Passionné de sport et d’innovation, j'ai imaginé et construit plusieurs prototypes jusqu’à ce que l’appareil soit performant et compact. Chaque version de l’appareil a été testé par des coachs sportifs dans le but de créer un appareil pertinent sur le plan sportif.",
                            <br></br>, <br></br>,
                            "En plus du KODNESS qui apporte une solution concrète aux contraintes matérielles des entraînements, j'ai développé une application mobile pour vous accompagner dans votre évolution sportive. L’objectif est de vous permettre d’atteindre vos objectifs sans contrainte avec une solution clé-en-main comportant un seul appareil compact et une seule application !"]}
                            english={["When he started to imagine the TRAINFAST, Thomas PERRIER intended to simplify the practice of fitness, so as to make it accessible to all!",
                            <br></br>, <br></br>,
                            "Indeed, he did not have enough space to install a classic weight station, which is therefore bulky, and he was not satisfied with the existing compact solutions which he found impractical and did not allow him to evolve sportingly correctly.",
                            <br></br>, <br></br>,
                            "Passionate about sport and innovation, he imagined and built several prototypes until the device was efficient and compact. Each version of the device was tested by sports coaches in order to create a relevant device. on a sporting level.",
                            <br></br>, <br></br>,
                            "In addition to KODNESS, which provides a concrete solution to the material constraints of training, it has developed a mobile application to support you in your sporting development. The objective is to allow you to achieve your objectives without constraints, with a single device and one app!"]}
                            spanish={["Cuando comenzó a imaginar el KODNESS, Thomas PERRIER pretendía simplificar la práctica del fitness para que fuera accesible a todos!",
                            <br></br>, <br></br>,
                            "De hecho, no tenía espacio suficiente para instalar una estación de pesas clásica, que por lo tanto es voluminosa, y no estaba satisfecho con las soluciones compactas existentes que le resultaban poco prácticas y no le permitían evolucionar deportivamente correctamente.",
                            <br></br>, <br></br>,
                            "Apasionado por el deporte y la innovación, imaginó y construyó varios prototipos hasta que el dispositivo fue eficiente y compacto. Cada versión del dispositivo fue probada por entrenadores deportivos para crear un dispositivo relevante a nivel deportivo",
                            <br></br>, <br></br>,
                            "Además de KODNESS, que aporta una solución concreta a las limitaciones materiales del entrenamiento, ha desarrollado una aplicación móvil para apoyarte en tu desarrollo deportivo. El objetivo es permitirte alcanzar tus objetivos sin limitaciones, con un solo dispositivo y ¡una aplicación!"]}
                            cssStyle={{width: "100%"}}
                        ></TextStyle>
                        <img className="aboutReason-apostrophe aboutReason-apostrophe-reverse" src={Apostrophe} alt=""></img>
                    </div>
                </div>
            </div>
        )

    }
}

export default AboutReason
