import React, { Component } from "react"
import "../../myStyle.css"
import "./AboutValues.css"
import TextStyle from "../style-compoment/TextStyle"

import local from "../../images/about-images/local.png"
import recyclage from "../../images/about-images/recyclage.png"
import light from "../../images/about-images/light.png"

class AboutValues extends Component {

    render () {

        return (    
            <div className="aboutValues-container">
                <div className="aboutValues-second">
                    <div className="aboutValues-third">
                        <img className="aboutValues-icon" src={local} alt="local"></img>
                        <TextStyle
                            id="a-31"
                            class="medium bold center animate"
                            french={["Fabriqué et", <br></br>, "conçu en France"]}
                            english="Fabriqué et conçu en France"
                            spanish="Fabriqué et conçu en France"
                        ></TextStyle>
                    </div>
                    <div className="aboutValues-third">
                        <img className="aboutValues-icon" src={light} alt="innovation"></img>
                        <TextStyle
                            id="a-32"
                            class="medium bold center animate"
                            french={["Technologie", <br></br>, "innovante brevetée"]}
                            english="Technologie innovante breveté"
                            spanish="Technologie innovante breveté"
                        ></TextStyle>
                    </div>
                    <div className="aboutValues-third">
                        <img className="aboutValues-icon" src={recyclage} alt="recyclable"></img>
                        <TextStyle
                            id="a-33"
                            class="medium bold center animate"
                            french={["L’acier est", <br></br>, " recyclable à 100%"]}
                            english="L’acier est recyclable à 100%"
                            spanish="L’acier est recyclable à 100%"
                        ></TextStyle>
                    </div>

                </div>

                
            
            </div>
        )

    }
}

export default AboutValues
