import React, { Component } from "react"
import Header from "../components/basics-compoment/Header"
import Footer from "../components/basics-compoment/Footer"

import TransitionBar from "../components/style-compoment/TransitionBar"

import AboutReason from "../components/about-compoment/AboutReason"
import AboutEvolution from "../components/about-compoment/AboutEvolution"
import AboutValues from "../components/about-compoment/AboutValues"
import AboutPublication from "../components/about-compoment/AboutPublication"
import IndexSubscribe from "../components/index-compoment/IndexSubscribe"

import "../myStyle.css"
import HelmetCompoment from "../components/basics-compoment/HelmetCompoment"


// markup
class About extends Component {

  state = {
    w: 100
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollAbout, true);

    if (window.screen.width < 500) {
      this.setState({
        w: 50
      })
    }

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollAbout);
  }
    
  
  handleScrollAbout = () => {
    let lastScrollY = window.scrollY;
    let windowSize = window.innerHeight

    const arrayID = ["a-11", "a-12", "a-21", "a-31", "a-32", "a-33", "a-41", "subscribe"]


      if (document.getElementById(arrayID[0]) !== null) {

        for(let i = 0; i < arrayID.length; i++) {

            const top = document.getElementById(arrayID[i]).offsetTop


            if ((top < (lastScrollY+windowSize-this.state.w)) &&  (top > lastScrollY-windowSize)) {

                const currentElement = document.getElementById(arrayID[i])
                currentElement.style.animationDuration = "800ms"
                currentElement.style.animationName = "titleAnimation"
                currentElement.style.opacity = "1"
                currentElement.style.transform = "translate(0%, 0%)"

          }
        }

      }





  }


  render () {

    return (
      <React.Fragment>

        <HelmetCompoment></HelmetCompoment>

          <Header page="about"></Header>
          <AboutReason></AboutReason>
          <TransitionBar></TransitionBar>
          <AboutEvolution></AboutEvolution>
          <AboutValues></AboutValues>
          <AboutPublication></AboutPublication>
          <TransitionBar></TransitionBar>
          <IndexSubscribe></IndexSubscribe>
        <Footer></Footer>
      </React.Fragment>

    )

  }

}

export default About



