import React, { Component } from "react"
import "./AboutPublication.css"

import right from "../../images/general-icon/right.png"
import left from "../../images/general-icon/left.png"

import TextStyle from "../style-compoment/TextStyle"
import transtech from "../../images/about-images/inventifs.jpg"
import transtechLogo from "../../images/about-images/transtech.jpeg"
import turbine from "../../images/about-images/Turbine.png"
import agcad from "../../images/about-images/agcad.jpg"
import cci from "../../images/about-images/cci.jpeg"

const one = <div className="aboutPublication-view-container">
                <img className="aboutPublication-image" src={transtech} alt=""></img>
                <TextStyle
                    class="small center italic"
                    french="Journal des inventifs" 
                    english="Journal des inventifs" 
                    spanish="Journal des inventifs"
                ></TextStyle>
            </div>
const two = <div className="aboutPublication-view-container">
                <a href="https://www.transtech.fr/" target="_blank" className="link">
                    <img className="aboutPublication-image" src={transtechLogo} alt=""></img>
                    <TextStyle
                        class="small center italic"
                        french="Transtech" 
                        english="Transtech" 
                        spanish="Transtech"
                    ></TextStyle>
                </a>
            </div>
const three = <div className="aboutPublication-view-container">
                <a href="https://laturbine-cergypontoise.fr/" target="_blank" className="link">
                    <img className="aboutPublication-image" src={turbine} alt=""></img>
                    <TextStyle
                        class="small center italic"
                        french="La Turbine" 
                        english="La Turbine" 
                        spanish="La Turbine"
                    ></TextStyle>
                </a>
            </div>
const four = <div className="aboutPublication-view-container">
                <a href="https://www.agekad.fr/" target="_blank" className="link">
                    <img className="aboutPublication-image" src={agcad} alt=""></img>
                    <TextStyle
                        class="small center italic"
                        french="Agekad" 
                        english="Agekad" 
                        spanish="Agekad"
                    ></TextStyle>
                </a>
            </div>
const five = <div className="aboutPublication-view-container">
                <a href="https://www.entreprises.cci-paris-idf.fr/web/cci92" target="_blank" className="link">
                    <img className="aboutPublication-image" src={cci} alt=""></img>
                    <TextStyle
                        class="small center italic"
                        french="CCI 92" 
                        english="CCI 92" 
                        spanish="CCI 92"
                    ></TextStyle>
                </a>
            </div>

        const views = [
            one,
            two,
            three,
            four,
            five,
            one,
            two,
            three,
            four,
            five,
        ]


class AboutPublication extends Component {


    state = {
        position: 5,
        screenWidth: 250
    }


    componentDidMount() {

        let itemWidth = 250

        this.setState({
            screenWidth: window.screen.width
        })

        if (window.screen.width < 500) {
           itemWidth = window.screen.width/2
        }
        const pos = this.state.position*itemWidth
        const aboutPublication = document.getElementById("id-aboutPublication-second")

        aboutPublication.style.scrollBehavior = "auto"
        aboutPublication.scrollTo(pos, 0)
        aboutPublication.style.scrollBehavior = "smooth"

    }


    carouselApp = (direction) => {

        let position = this.state.position
        const aboutPublication = document.getElementById("id-aboutPublication-second")
        const windowWidth = aboutPublication.offsetWidth

        let itemWidth = 250
        if (this.state.screenWidth < 500) {
           itemWidth = this.state.screenWidth/2
        }

        const numberItem = parseInt(windowWidth/itemWidth)
        const items = 5

        if (direction === "left") {
            if (position > 1) {
                position -= 1
            } else {
                position = items

                aboutPublication.style.scrollBehavior = "auto"
                const pos = itemWidth*(position+1)
                aboutPublication.scrollTo(pos, 0)
                aboutPublication.style.scrollBehavior = "smooth"
            }
        }

        if (direction === "right") {
            if (position < views.length-numberItem) {
                position += 1
            } else {
                position = items + 1 - numberItem

                aboutPublication.style.scrollBehavior = "auto"
                const pos = itemWidth*(position-1)
                aboutPublication.scrollTo(pos, 0)
                aboutPublication.style.scrollBehavior = "smooth"

            }
        }

        console.log('position ', position)

        this.setState({
            position: position
        })

        const newPosition = itemWidth*position

        aboutPublication.scrollTo(newPosition, 0)

     }


    render () {

    

    

        return (    
            <div className="aboutPublication-container">
                    <TextStyle
                        id="a-41"
                        class="title center big animate"
                        french={[<span className="orange">Publication</span>, " dans",<br></br>,"la presse et ", <span className="orange">partenaires</span>]}
                        english={["Publication in",<br></br>,"the press and partners"]}
                        spanish={["Publicación",<br></br>,"en prensa y socios"]}
                        cssStyle={{marginTop: "50px", marginBottom: "20px", textAlign: "center"}}
                    ></TextStyle>
                <div id="id-aboutPublication-second" className="aboutPublication-second">
                    <div className="aboutPublication-three">
                        {views}
                    </div>
                </div>
                <div className="aboutPublication-arrow-container">
                    <img onClick={() => this.carouselApp('left')} className="aboutPublication-arrow" src={left} alt=""></img>
                    <img onClick={() => this.carouselApp('right')} className="aboutPublication-arrow" src={right} alt=""></img>
                </div>
            </div>
        )

    }
}

export default AboutPublication
